.Footer-container {
    position: relative;
  }
  
  .Footer-container > hr {
    border: 1px solid var(--lightgray);
  }
  
  .footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
  }
  
  .social-links {
    display: flex;
    gap: 4rem;
  }
  
  .social-links > a {
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .social-links > a > img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }
  
  .logo-f > img {
    width: 10rem;
  }
  
  .Footer-container > :nth-child(3) {
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
  }
  
  .Footer-container > :nth-child(4) {
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0);
  }
  